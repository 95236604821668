import { useEffect, useCallback, useState } from "react";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import { Functions, httpsCallable } from "firebase/functions";
import Button from "anchor-ui/button";
import "./styles.css";

const PAYMENT_SESSION_PREFIX = "paymentSession_";
const SESSION_EXPIRATION_MS = 600000; // 10 minutes
const MAX_RETRY_ATTEMPTS = 3;
const RETRY_INTERVAL_MS = 2000; // 2 seconds

interface PaymentVerification {
  status: string;
  amount?: number;
  currency?: string;
  description?: string;
  orderReference: string;
}

const PaymentStatusPage = ({
  loggedInUser,
  isLoading,
  reloadCometChatRole,
  payFunctions,
  setAlert,
}: {
  loggedInUser: CometChat.User | null | undefined;
  isLoading: boolean;
  reloadCometChatRole: () => Promise<boolean>;
  payFunctions: Functions;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
}) => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentVerification | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [wasLoggedIn, setWasLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  const getCachedSessionId = useCallback(() => {
    if (!loggedInUser) return null;

    const CACHE_KEY = `${PAYMENT_SESSION_PREFIX}${loggedInUser.getUid()}`;
    const cachedData = localStorage.getItem(CACHE_KEY);

    if (!cachedData) return null;

    const { sessionId, timestamp } = JSON.parse(cachedData);
    const isExpired = Date.now() - timestamp > SESSION_EXPIRATION_MS;

    if (isExpired) {
      // Cleanup expired sessions
      Object.keys(localStorage)
        .filter((key) => key.startsWith(PAYMENT_SESSION_PREFIX))
        .forEach((key) => {
          const sessionData = JSON.parse(localStorage.getItem(key) || "{}");
          if (Date.now() - sessionData.timestamp > SESSION_EXPIRATION_MS) {
            localStorage.removeItem(key);
          }
        });

      return null;
    }

    return sessionId;
  }, [loggedInUser, wasLoggedIn]);

  const checkPaymentStatus = useCallback(async (orderRef: string, retryCount = 0): Promise<PaymentVerification | null> => {
    try {
      const verifyPayment = httpsCallable(payFunctions, "verifyStripePayment");
      const result = await verifyPayment({ orderRef });

      const resultData = result.data as PaymentVerification;

      if (resultData.status === "success") {
        return resultData;
      }

      // If payment is not successful and we haven't reached max retries
      if (retryCount < MAX_RETRY_ATTEMPTS) {
        // Wait for 2 seconds before retrying
        await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL_MS));

        // Retry with incremented retry count
        return checkPaymentStatus(orderRef, retryCount + 1);
      }

      // If all retries fail, return the last result
      return resultData;
    } catch (err) {
      // If there's an error and we haven't reached max retries
      if (retryCount < MAX_RETRY_ATTEMPTS) {
        // Wait for 2 seconds before retrying
        await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL_MS));

        // Retry with incremented retry count
        return checkPaymentStatus(orderRef, retryCount + 1);
      }

      // If all retries fail, throw the error
      throw err;
    }
  }, [payFunctions]);

  const processPaymentStatus = useCallback(async (orderRef: string) => {
    try {
      setLoading(true);
      const resultData = await checkPaymentStatus(orderRef);

      if (resultData) {
        setPaymentStatus(resultData);

        if (resultData.status === "success") {
          setAlert({ message: `Betaling succesvol voor ${resultData.description}`, type: "success" });
          const enabled = await reloadCometChatRole();
          setButtonEnabled(enabled);
        } else {
          setAlert({ message: `Betaling mislukt voor ${resultData.description}`, type: "error" });
        }
      }
    } catch (err) {
      console.error("Payment verification error:", err);
      setError("Kan betalingsstatus niet verifiëren");
    } finally {
      setLoading(false);
    }
  }, [checkPaymentStatus, reloadCometChatRole, setAlert]);

  useEffect(() => {
    if (isLoading || wasLoggedIn) return;

    if (!loggedInUser && !wasLoggedIn) {
      navigate("/login");
      return;
    }
    setWasLoggedIn(true);
    const orderRef = getCachedSessionId();

    if (orderRef) {
      processPaymentStatus(orderRef);
    } else {
      setError("Payment session is invalid or expired.");
      setLoading(false);
    }
  }, [isLoading, wasLoggedIn, processPaymentStatus]);

  // Loading state
  if (isLoading) {
    return (
      <Layout heroText="Payment Status">
        <div>Loading...</div>
      </Layout>
    );
  }

  // User not logged in
  if (!loggedInUser && !wasLoggedIn) {
    return (
      <Layout heroText="Payment Status">
        <div>User not logged in, redirecting...</div>
      </Layout>
    );
  };

  return (
    <Layout heroText="Betalingsstatus">
      <div className="payment-status-container">
        <h2 className="payment-status-subtitle">Resultaat van uw betaling</h2>
        {loading && <p className="payment-status-loader">Betalingsstatus laden...</p>}
        {error && <div className="payment-status-error">{
          error === "Unable to verify payment status"
            ? "Kan betalingsstatus niet verifiëren"
            : error === "Payment session is invalid or expired."
              ? "Geen betaalsessie gevonden."
              : error
        }</div>}
        {paymentStatus && (
          <div className="payment-details">
            <p className="payment-status-text">
              Status: <span style={{ color: paymentStatus.status === 'success' ? '#27ae60' : '#e74c3c' }}>
                {paymentStatus.status === 'success' ? 'GESLAAGD' : paymentStatus.status + ""}
              </span>
            </p>
            {paymentStatus.status === 'success' && (
              <>
                <p className="payment-status-text" style={{ fontWeight: 'bold' }}>Bedankt voor uw aankoop!</p>
                <p className="payment-status-text">Het kan tot 24 uur duren voordat uw rol is bijgewerkt.</p>
              </>
            )}
            {paymentStatus.description && (
              <p className="payment-status-text">{paymentStatus.description}</p>
            )}
            {paymentStatus.amount && paymentStatus.currency && (
              <p className="payment-amount">
                Bedrag: €{(paymentStatus.amount / 100).toFixed(2)} {paymentStatus.currency.toUpperCase()}
              </p>
            )}
          </div>
        )}
        <Button
          className="payment-btn"
          onClick={() => navigate("/chat")}
          disabled={!buttonEnabled}
        >
          Ga naar chat
        </Button>
      </div>
    </Layout>
  );
};

export default PaymentStatusPage;